<div class="content">
    <div>Login by entering your Orchestrate API Key</div>

    <div><a href="https://rosetta.careevolution.com" target="_blank">Create an account</a></div>

    <div class="apikey">
        <mat-form-field appearance="outline">
            <mat-label>API Key</mat-label>
            <input matInput placeholder="API Key" [(ngModel)]="apiKey">
        </mat-form-field>
    </div>

    <div>
        <button mat-raised-button color="primary" (click)="login()">Login</button>
    </div>
</div>