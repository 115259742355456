<table mat-table [dataSource]="dataSource" [multiTemplateDataRows]="true">
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>Code</th>
    <td mat-cell *matCellDef="let element"><code>{{element.code}}</code></td>
  </ng-container>

  <ng-container matColumnDef="display">
    <th mat-header-cell *matHeaderCellDef>Display</th>
    <td mat-cell *matCellDef="let element">{{element.display}}</td>
  </ng-container>

  <ng-container matColumnDef="valueSets">
    <th mat-header-cell *matHeaderCellDef>
      <button mat-raised-button *ngIf="pinnedValueSets.length > 0" type="button" (click)="clearPinnedValueSets()">
        Clear
      </button>
    </th>
    <td mat-cell *matCellDef="let element" class="value-sets-options">
      <button mat-raised-button *ngIf="hasValueSets(element)" type="button" (click)="showValueSets(element)">
        {{element.valueSets.length}} value sets
      </button>
      <span *ngIf="!hasValueSets(element)">0 value sets</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="relatedTerms">
    <td mat-cell *matCellDef="let element" [attr.colspan]="relatedTermsColSpan">
      <div *ngIf="hasRelatedTerms(element)" class="inline-detail">
        <span>Related to </span>
        <ul>
          <li *ngFor="let c of element.relatedTerms">
            <span class="system">{{c.systemId}}</span>
            <span class="code">{{c.code}}</span>
            <span class="display">{{c.display}}</span>
          </li>
        </ul>
      </div>
    </td>
  </ng-container>

  <ng-container *ngFor="let vs of pinnedValueSets" [matColumnDef]="vs.key">
    <th mat-header-cell *matHeaderCellDef class="value-set-header">{{vs.scope}}<br>{{vs.name}}</th>
    <td mat-cell *matCellDef="let element" class="value-set-cell">
      <mat-icon>{{element.isInValueSet(vs) ? 'done' : 'radio_button_unchecked'}}</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="getCodingColumns()"></tr>
  <tr mat-row *matRowDef="let row; columns: getCodingColumns();" class="coding"></tr>
  <tr mat-row *matRowDef="let row; columns: ['relatedTerms'];" [class.empty]="!hasRelatedTerms(row)"
    class="related-terms"></tr>
</table>

<div *ngIf="(dataSource.searchCount$ | async) === 0" class="not-found">
  No codings found
</div>

<mat-paginator hidePageSize showFirstLastButtons [length]="dataSource.searchCount$ | async" [pageSize]="pageSize"
  [disabled]="dataSource.loading$ | async">
</mat-paginator>
