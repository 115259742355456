import { Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Coding } from 'src/app/models/coding.model';
import { Pipeline, PipelineMapper } from 'src/app/models/pipeline.model';
import { TranslateResults } from 'src/app/models/translate-results.model';

@Component({
  selector: 'app-translate-results',
  templateUrl: './translate-results.component.html',
  styleUrls: ['./translate-results.component.scss']
})
export class TranslateResultsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public results: TranslateResults;

  @Input()
  public pipeline: Pipeline;

  public tableRows$: Observable<MapperTableRowViewModel[]>;

  private tableRowsSource = new Subject<MapperTableRow[]>();
  private tableRowsEdited = new BehaviorSubject<boolean>(true);

  constructor() {
    this.tableRows$ = combineLatest([
      this.tableRowsEdited,
      this.tableRowsSource]).pipe(
        map(([_, rows]) => {
          const vms = rows.map(row => new MapperTableRowViewModel(row));
          return vms;
        }));
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.results && this.pipeline) {
      this.setMappingsAndCrosswalks();
    }
  }

  ngOnDestroy(): void {
    this.tableRowsSource.complete();
    this.tableRowsEdited.complete();
  }

  isSegment = (_: number, item: MapperTableRowViewModel): boolean => item.isSegment;
  isMapping = (_: number, item: MapperTableRowViewModel): boolean => item.isMapping;
  isNotMapped = (_: number, item: MapperTableRowViewModel): boolean => item.isNotMapped;
  isNotRun = (_: number, item: MapperTableRowViewModel): boolean => item.isNotRun;
  isCrosswalk = (_: number, item: MapperTableRowViewModel): boolean => item.isCrosswalk;
  showDetails = (_: number, item: MapperTableRowViewModel): boolean => item.showDetails;

  toggleExpansion(rowVM: MapperTableRowViewModel): void {
    rowVM.row.showDetails = !rowVM.showDetails;
    this.tableRowsEdited.next(true);
  }

  setMappingsAndCrosswalks(): void {
    const tableRows = [
      MapperTableRow.forSegment('Primary')
    ];

    this.pipeline.primary
      .map((mapper, index) => this.buildMapperRows(index, mapper, this.results))
      .forEach(rows => {
        tableRows.push(...rows);
      });

    tableRows.push(MapperTableRow.forSegment('Fallback'));

    this.pipeline.fallback
      .map((mapper, index) => this.buildMapperRows(index + this.pipeline.primary.length, mapper, this.results))
      .forEach(rows => tableRows.push(...rows));

    this.tableRowsSource.next(tableRows);
  }

  private buildMapperRows(index: number, mapper: PipelineMapper, results: TranslateResults): MapperTableRow[] {
    const rows: MapperTableRow[] = [];

    const mapperRun = results.trace?.split('\n').map(line => line.trim()).indexOf(mapper.mapperId) >= 0;

    if (!!results.trace && !mapperRun) {
      rows.push(MapperTableRow.forNotRun(index, mapper, mapper.targetCodeSystem));
    } else {
      const result = results.mappings.find(mapping => mapping.mapper === mapper.mapperId);

      if (!result) {
        rows.push(MapperTableRow.forNoMapping(index, mapper, mapper.targetCodeSystem));
      } else {
        const target = result.target;

        let details = result.technique;

        if (result.detail) {
          details += '/' + result.detail;
        }

        if (result.confidence > 0) {
          details += ' score ' + result.confidence;
        }

        rows.push(MapperTableRow.forMapping(index, mapper, target, details));

        const crosswalkSources = [target];

        const crosswalkMappings = results.mappings.filter(m => m.technique === 'Crosswalk');

        while (crosswalkSources.length) {
          let crosswalkSource = crosswalkSources.pop();

          while (crosswalkSource) {
            const crosswalkTargetIndex = crosswalkMappings
              .findIndex(m => m.crosswalkSourceSystem === crosswalkSource.systemId);

            if (crosswalkTargetIndex >= 0) {
              const crosswalkTarget = crosswalkMappings.splice(crosswalkTargetIndex, 1)[0];

              rows.push(MapperTableRow.forCrosswalk(crosswalkTarget.target, crosswalkSources.length));
              crosswalkSources.push(crosswalkSource);
              crosswalkSource = crosswalkTarget.target;
            } else {
              crosswalkSource = null;
            }
          }
        }
      }
    }
    return rows;
  }
}

export class MapperTableRow {
  public isSegment: boolean;
  public isMapping: boolean;
  public isNotMapped: boolean;
  public isNotRun: boolean;
  public isCrosswalk: boolean;

  public index?: number;
  public title?: string;
  public mapperId?: string;
  public systemId?: string;
  public target?: Coding;
  public categorizers: string[];
  public depth: number;

  public tooltip: string;

  public showDetails: boolean;

  public static forMapping(index: number, mapper: PipelineMapper, target: Coding, tooltip: string): MapperTableRow {
    const vm = new MapperTableRow();
    vm.index = index;
    vm.isMapping = true;
    vm.mapperId = mapper.mapperId;
    vm.categorizers = mapper.categorizers;
    vm.target = target;
    vm.systemId = target.systemId;
    vm.tooltip = tooltip;
    return vm;
  }

  public static forCrosswalk(target: Coding, depth: number): MapperTableRow {
    const vm = new MapperTableRow();
    vm.isCrosswalk = true;
    vm.systemId = target.systemId;
    vm.target = target;
    vm.depth = depth;
    return vm;
  }

  public static forNotRun(index: number, mapper: PipelineMapper, systemId: string): MapperTableRow {
    const vm = new MapperTableRow();
    vm.index = index;
    vm.isNotRun = true;
    vm.mapperId = mapper.mapperId;
    vm.categorizers = mapper.categorizers;
    vm.systemId = systemId;
    return vm;
  }

  public static forNoMapping(index: number, mapper: PipelineMapper, systemId: string): MapperTableRow {
    const vm = new MapperTableRow();
    vm.index = index;
    vm.isNotMapped = true;
    vm.mapperId = mapper.mapperId;
    vm.categorizers = mapper.categorizers;
    vm.systemId = systemId;
    return vm;
  }

  public static forSegment(title: string): MapperTableRow {
    const vm = new MapperTableRow();
    vm.isSegment = true;
    vm.title = title;
    return vm;
  }
}

export class MapperTableRowViewModel {
  public row: MapperTableRow;

  constructor(row: MapperTableRow) {
    this.row = row;
  }

  public get isSegment(): boolean { return this.row.isSegment; }
  public get isMapping(): boolean { return this.row.isMapping; }
  public get isNotMapped(): boolean { return this.row.isNotMapped; }
  public get isNotRun(): boolean { return this.row.isNotRun; }
  public get isCrosswalk(): boolean { return this.row.isCrosswalk; }
  public get title(): string { return this.row.title; }
  public get index(): number { return this.row.index + 1; }
  public get mapperId(): string { return this.row.mapperId; }
  public get systemId(): string { return this.row.systemId; }
  public get target(): Coding { return this.row.target; }
  public get categorizers(): string[] { return this.row.categorizers; }
  public get tooltip(): string { return this.row.tooltip; }
  public get showDetails(): boolean { return this.row.showDetails; }
  public get depth(): number { return this.row.depth; }
}
