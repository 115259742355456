<table mat-table [dataSource]="tableRows$ | async" multiTemplateDataRows class="mat-table">
  <ng-container matColumnDef="mapperIndex">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let result" class="mapper-index">{{result.index}}</td>
  </ng-container>

  <ng-container matColumnDef="mapperId">
    <th mat-header-cell *matHeaderCellDef>Mapper</th>
    <td mat-cell *matCellDef="let result">
      <app-mapper-label [mapper]="result.mapperId" [categorizers]="result.categorizers"></app-mapper-label>
    </td>
  </ng-container>

  <ng-container matColumnDef="systemId">
    <th mat-header-cell *matHeaderCellDef>System</th>
    <td mat-cell *matCellDef="let result">
      <app-code-system-label [codeSystem]="result.systemId"></app-code-system-label>
    </td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>Code</th>
    <td mat-cell *matCellDef="let result">{{result.target.code}}</td>
  </ng-container>

  <ng-container matColumnDef="display">
    <th mat-header-cell *matHeaderCellDef>Display</th>
    <td mat-cell *matCellDef="let result">{{result.target.display}}</td>
  </ng-container>

  <ng-container matColumnDef="noMapping">
    <td mat-cell *matCellDef="let result" class="no-mapping" colspan="6">⊘ No mapping</td>
  </ng-container>

  <ng-container matColumnDef="notRun">
    <td mat-cell *matCellDef="let result" class="no-mapping" colspan="6">Not run</td>
  </ng-container>

  <ng-container matColumnDef="crosswalk">
    <td mat-cell *matCellDef="let result" class="crosswalk">
      <span class="crosswalk-depth" *ngFor="let x of result.depth|times"></span>↪ crosswalked to
    </td>
  </ng-container>

  <ng-container matColumnDef="expander">
    <td mat-cell *matCellDef="let result" class="expander">
      <button mat-icon-button (click)="toggleExpansion(result)">
        <mat-icon>{{result.showDetails ? "unfold_less" : "unfold_more"}}</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="details-label">
    <td mat-cell *matCellDef="let result" class="details-label">
      Mapping Details:
    </td>
  </ng-container>

  <ng-container matColumnDef="technique">
    <td mat-cell *matCellDef="let result" class="details" colspan="5">
      {{result.tooltip}}
    </td>
  </ng-container>

  <ng-container matColumnDef="segment">
    <td mat-cell *matCellDef="let result" class="segment" colspan="6">
      <h4>{{result.title}}</h4>
    </td>
  </ng-container>

  <ng-container matColumnDef="empty">
    <td mat-cell *matCellDef="let result" class="empty">
    <td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['mapperIndex', 'mapperId','systemId','code','display']"></tr>
  <tr mat-row *matRowDef="let result; columns ['segment']; when: isSegment"></tr>
  <tr mat-row
    *matRowDef="let result; columns ['mapperIndex', 'mapperId','systemId','code','display','expander']; when: isMapping">
  </tr>
  <tr mat-row *matRowDef="let result; columns ['empty','details-label','technique']; when: showDetails"></tr>
  <tr mat-row *matRowDef="let result; columns ['mapperIndex', 'mapperId','systemId','noMapping']; when isNotMapped">
  </tr>
  <tr mat-row *matRowDef="let result; columns ['mapperIndex', 'mapperId','systemId','notRun']; when: isNotRun"
    class="not-run"></tr>
  <tr mat-row
    *matRowDef="let result; columns ['empty','crosswalk','systemId','code','display','empty']; when: isCrosswalk">
  </tr>
</table>
