<form class="mapper-container content">
  <div class="source">
    <h3>Source Term</h3>
    <h4>Domain</h4>
    <mat-form-field>
      <mat-label>Domain</mat-label>
      <mat-select [(ngModel)]="source.domain" [ngModelOptions]="{standalone: true}"
        (selectionChange)="onSourceChanged()">
        <mat-option *ngFor="let domain of domains" [value]="domain">{{domain}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>System</mat-label>
      <input matInput placeholder="System" (change)="onSourceChanged()" [(ngModel)]="source.system"
        [ngModelOptions]="{standalone: true}">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Code</mat-label>
      <input matInput placeholder="Code" (change)="onSourceChanged()" [(ngModel)]="source.code"
        [ngModelOptions]="{standalone: true}">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Display</mat-label>
      <input matInput placeholder="Display" (change)="onSourceChanged()" [(ngModel)]="source.display"
        [ngModelOptions]="{standalone: true}">
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit" (click)="translate()"
      [disabled]="!canTranslate()">Translate</button>
    <button mat-raised-button type="button" (click)="clearSource()">Clear</button>
  </div>
  <div class="translate-results" *ngIf="results">
    <div class="results-container">
      <div class="cat-and-trace" *ngIf="results.trace">
        <app-categorization [categorizations]="results.categorizations"></app-categorization>
        <button mat-raised-button (click)="showTrace()">Show Trace</button>
      </div>
      <app-translate-results [results]="results" [pipeline]="pipeline"></app-translate-results>
    </div>
  </div>
</form>
