import { Component, Input, OnInit } from '@angular/core';
import { Categorization } from 'src/app/models/translate-results.model';

@Component({
  selector: 'app-categorization',
  templateUrl: './categorization.component.html',
  styleUrls: ['./categorization.component.css']
})
export class CategorizationComponent implements OnInit {

  @Input()
  public categorizations: Categorization[];

  constructor() { }

  ngOnInit(): void {
  }

}
