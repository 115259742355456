import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(!this.authService.isAuthenticated) {
      throw new Error('Not authenticated');
    }
    const authRequest = request.clone({
      headers: request.headers.set('x-api-key', this.authService.apiKey)
    });
    return next.handle(authRequest);
  }
}
