<div class="content">
  <div>
    Select Code System:
    <app-code-system-selector [codeSystem]="defaultCodeSystem" [codeSystems]="rosetta.codeSystems"
      (codeSystemChange)="setCodeSystem($event)">
    </app-code-system-selector>
  </div>

  <div *ngIf="codeSystem$ | async">
    <app-search-bar [search$]="setSearch$" (searchChange)="setSearch($event)"></app-search-bar>
    <app-basic-coding-table [search$]="search$" [codeSystem$]="codeSystem$"></app-basic-coding-table>
  </div>
</div>