import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValueSetReference } from 'src/app/models/value-set-reference.model';
import { sortLocale, sortOptions } from '../../sorting';
import { ValueSetScopeViewModel } from '../value-set-scope.viewmodel';
import { ValueSetViewModel } from '../value-set.viewmodel';
import { ValueSetsDialogModel } from './value-sets-dialog.model';

@Component({
  selector: 'app-value-sets-dialog',
  templateUrl: './value-sets-dialog.component.html',
  styleUrls: ['./value-sets-dialog.component.scss']
})
export class ValueSetsDialogComponent {

  public scopes: ValueSetScopeViewModel[];
  public code: string;
  public display: string;

  private pinnedValueSets: ValueSetReference[];

  constructor(
    public dialogRef: MatDialogRef<ValueSetsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogModel: ValueSetsDialogModel
  ) {
    this.code = dialogModel.code;
    this.display = dialogModel.display;

    const valueSetsByScope = new Map<string, ValueSetViewModel[]>();
    dialogModel.valueSets.forEach(vs => {
      const isPinned = !!dialogModel.pinnedValueSets.find(pvs => pvs.scope === vs.scope && pvs.name === vs.name);

      const vsViewModel = new ValueSetViewModel(vs, isPinned);

      const forScope = valueSetsByScope.get(vs.scope);
      if (!forScope) {
        valueSetsByScope.set(vs.scope, [vsViewModel]);
      } else {
        forScope.push(vsViewModel);
      }
    });

    this.scopes = [];
    this.pinnedValueSets = [...dialogModel.pinnedValueSets];

    valueSetsByScope.forEach((valueSets, key) => {
      valueSets.sort((a, b) => a.cleanName.localeCompare(b.cleanName, sortLocale, sortOptions));
      this.scopes.push(new ValueSetScopeViewModel(key, valueSets));
    });

    this.scopes.sort((a, b) => a.scope.localeCompare(b.scope, sortLocale, sortOptions));
  }

  onPinnedChanged(valueSet: ValueSetViewModel, checked: boolean): void {
    if (checked) {
      this.pinnedValueSets.push(valueSet.reference);
    } else {
      const index = this.pinnedValueSets.findIndex(vs => vs.isMatch(valueSet.reference));
      this.pinnedValueSets.splice(index, 1);
    }
  }

  onCloseClick(): void {
    this.dialogRef.close(this.pinnedValueSets);
  }
}
