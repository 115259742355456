import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CodeSystem } from '../models/code-system.model';
import { RosettaService } from '../services/rosetta.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  public codeSystem$: Observable<CodeSystem>;
  public search$: Observable<string>;
  public setSearch$: Observable<string>;
  public defaultCodeSystem: CodeSystem;

  private destroySubject = new ReplaySubject<void>(1);
  private codeSystemSubject = new ReplaySubject<CodeSystem>(1);
  private searchSubject = new BehaviorSubject<string>('');
  private setSearchSubject = new ReplaySubject<string>(1);

  constructor(
    public rosetta: RosettaService,
    private activatedRoute: ActivatedRoute
  ) {
    this.codeSystem$ = this.codeSystemSubject.asObservable();
    this.search$ = this.searchSubject.pipe(
      takeUntil(this.destroySubject),
      map(search => search.trim()),
    );
    this.setSearch$ = this.setSearchSubject.asObservable();
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      const codeSystems = data.codeSystems as CodeSystem[];

      if (codeSystems) {

        this.defaultCodeSystem = codeSystems.find(cs => cs.id === CodeSystem.snomed);
        if (this.defaultCodeSystem) {
          this.setCodeSystem(this.defaultCodeSystem);
        }
        this.setSearchSubject.next('myocardial infarction');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
  }

  setCodeSystem(codeSystem: CodeSystem) {
    this.codeSystemSubject.next(codeSystem);
  }

  setSearch(search: string) {
    this.searchSubject.next(search);
  }
}
