import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperationOutcome } from '../models/operation-outcome.model';

@Component({
  selector: 'app-rosetta-error-dialog',
  templateUrl: './rosetta-error-dialog.component.html',
  styleUrls: ['./rosetta-error-dialog.component.css']
})
export class RosettaErrorDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public error: RosettaErrorDialogData) {
    console.log(error);
  }

  ngOnInit(): void {
  }
}

export interface RosettaErrorDialogData {
  message: string;
  error: OperationOutcome;
}
