import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HighlightService } from 'src/app/services/highlight.service';

@Component({
  selector: 'app-mapper-label',
  templateUrl: './mapper-label.component.html',
  styleUrls: ['./mapper-label.component.scss']
})
export class MapperLabelComponent implements OnInit, OnDestroy {

  @Input()
  public mapper: string;

  @Input()
  public categorizers: string[];

  public shouldHighlight = false;

  private highlightSubscription: Subscription;

  constructor(private highlightService: HighlightService) {

  }

  ngOnInit(): void {
    this.highlightSubscription = this.highlightService.categorizers$
      .subscribe(categorizers => {
        this.shouldHighlight = false;
        if (categorizers?.length > 0) {
          for (const c of categorizers) {
            if (this.categorizers.indexOf(c) >= 0) {
              this.shouldHighlight = true;
              return;
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.highlightSubscription.unsubscribe();
  }

  onMouseEnter(): void {
    this.highlightService.startHighlightingCategorizer(this.categorizers);
  }

  onMouseLeave(): void {
    this.highlightService.stopHighlightingCategorizer();
  }
}
