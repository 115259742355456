import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RosettaService } from '../services/rosetta.service';
import { SearchParserService } from '../services/search-parser.service';
import { TranslateResultsViewModel } from './translate-resutls.viewmodel';

const sortLocale = 'en';
const sortOptions = { sensitivity: 'base' };

@Component({
  selector: 'app-smart-search',
  templateUrl: './smart-search.component.html',
  styleUrls: ['./smart-search.component.css']
})
export class SmartSearchComponent implements OnInit, OnDestroy {

  public translateResults$: Observable<TranslateResultsViewModel[]>;
  public setSearch$: Observable<string>;

  private destroySubject: ReplaySubject<boolean>;
  private searchCancelSubject: Subject<void>;
  private translateResultsSubject: ReplaySubject<TranslateResultsViewModel[]>;
  private setSearchSubject: ReplaySubject<string>;

  constructor(private rosetta: RosettaService, private searchParser: SearchParserService) {
    this.destroySubject = new ReplaySubject<boolean>(1);
    this.searchCancelSubject = new Subject<void>();
    this.setSearchSubject = new ReplaySubject<string>(1);
    this.translateResultsSubject = new ReplaySubject<TranslateResultsViewModel[]>();
    this.translateResults$ = this.translateResultsSubject.asObservable();
    this.setSearch$ = this.setSearchSubject.asObservable();
  }

  ngOnInit(): void {
    this.setSearchSubject.next('diabetes type 2');
  }

  ngOnDestroy(): void {
    this.destroySubject.next(true);
  }

  onSearchChange(search: string) {
    this.searchCancelSubject.next();

    const searchString = this.searchParser.parse(search);

    this.rosetta.translate(searchString.search, null, searchString.domain, searchString.system, null, this.searchCancelSubject).pipe(
      takeUntil(this.destroySubject),
      map(results => results.mappings.map(r => new TranslateResultsViewModel(r)))
    ).subscribe(vms => {
      this.translateResultsSubject.next(vms);
      for (const vm of vms) {
        this.rosetta.getAdditionalInfo(vm.systemId, vm.code, this.searchCancelSubject).pipe(
          takeUntil(this.destroySubject)
        ).subscribe(additionalInfo => {
          const names = new Set<string>(additionalInfo.valueSets.map(vs => vs.name.replace(/ ?\[[^\]]+\]$/, '')));
          vm.valueSets = [...names];
          vm.valueSets.sort((a, b) => a.localeCompare(b, sortLocale, sortOptions));
        });
      }
    });
  }

  hasValueSets(row: TranslateResultsViewModel): boolean {
    return row.valueSets.length > 0;
  }
}
