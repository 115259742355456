<h2 mat-dialog-title>Rosetta Error</h2>

<mat-dialog-content class="mat-typography">
  <p>{{error.message}}</p>

  <table *ngIf="error.error?.issue?.length > 0">
    <thead>
      <tr>
        <th>Code</th>
        <th>Severity</th>
        <th>Diagnostics</th>
    </thead>
    <tbody>
      <tr *ngFor="let issue of error.error.issue">
        <td><code>{{issue.code}}</code></td>
        <td><code>{{issue.severity}}</code></td>
        <td>{{issue.diagnostics}}</td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
