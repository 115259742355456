import { Coding } from './coding.model';

export class TranslateResults {
  constructor(
    public categorizations: Categorization[],
    public mappings: TranslateMapping[],
    public sourceSystems: string[],
    public trace: string) {
  }
}

export class Categorization {
  constructor(
    public categorizer: string,
    public category: string) {
  }
}

export class TranslateMapping {
  constructor(
    public target: Coding,
    public equivalence: string,
    public technique: string,
    public detail: string,
    public confidence: number,
    public mapper: string,
    public crosswalkSourceSystem: string) {
  }
}
