<app-search-bar [search$]="setSearch$" (searchChange)="onSearchChange($event)"></app-search-bar>

<table mat-table [dataSource]="translateResults$ | async" [multiTemplateDataRows]="true">
  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>Code</th>
    <td mat-cell *matCellDef="let element"><code>{{element.code}}</code></td>
  </ng-container>

  <ng-container matColumnDef="display">
    <th mat-header-cell *matHeaderCellDef>Display</th>
    <td mat-cell *matCellDef="let element">{{element.display}}</td>
  </ng-container>

  <ng-container matColumnDef="system">
    <th mat-header-cell *matHeaderCellDef>System</th>
    <td mat-cell *matCellDef="let element"><code>{{element.systemId}}</code></td>
  </ng-container>

  <ng-container matColumnDef="technique">
    <th mat-header-cell *matHeaderCellDef>Technique</th>
    <td mat-cell *matCellDef="let element">{{element.technique}} <span *ngIf="element.detail"
        class="detail">({{element.detail}})</span></td>
  </ng-container>

  <ng-container matColumnDef="confidence">
    <th mat-header-cell *matHeaderCellDef>Confidence</th>
    <td mat-cell *matCellDef="let element"><code>{{element.confidence | number:'1.1-1'}}</code></td>
  </ng-container>

  <ng-container matColumnDef="valueSets">
    <td mat-cell *matCellDef="let element" colspan="5">
      <div *ngIf="hasValueSets(element)" class="inline-detail">
        <span>In value sets </span>
        <ul>
          <li *ngFor="let vs of element.valueSets; last as isLast">{{vs}}<span *ngIf="!isLast">, </span></li>
        </ul>
      </div>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['system', 'code', 'display', 'technique', 'confidence']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['system', 'code', 'display', 'technique', 'confidence'];" class="coding">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['valueSets'];" [class.empty]="!hasValueSets(row)" class="value-sets"></tr>
</table>

<div *ngIf="(translateResults$ | async)?.length === 0" class="not-found">
  No codings found
</div>
