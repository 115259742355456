import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  public apiKey: string = null;

  constructor(
    private authService: AuthService,
    private router: Router) {
    this.apiKey = authService.apiKey;
  }


  public login() {
    this.authService.onSignIn(this.apiKey);
    if(this.authService.isAuthenticated) {
      this.router.navigate(['home']);
    }
  }
}
