import { Component, Input, OnInit } from '@angular/core';
import { Pipeline } from 'src/app/models/pipeline.model';

@Component({
  selector: 'app-pipeline-detail',
  templateUrl: './pipeline-detail.component.html',
  styleUrls: ['./pipeline-detail.component.scss'],
})
export class PipelineDetailComponent implements OnInit {

  @Input()
  public pipeline: Pipeline;

  constructor() { }

  ngOnInit(): void {
  }

}
