import { TranslateMapping } from '../models/translate-results.model';

export class TranslateResultsViewModel {
  public code: string;
  public systemId: string;
  public display: string;
  public equivalence: string;
  public technique: string;
  public detail: string;
  public confidence: number;
  public valueSets: string[];

  constructor(translateResults: TranslateMapping) {
    this.code = translateResults.target.code;
    this.systemId = translateResults.target.systemId;
    this.display = translateResults.target.display;
    this.equivalence = translateResults.equivalence;
    this.technique = translateResults.technique;
    this.detail = translateResults.detail;
    this.confidence = translateResults.confidence;
    this.valueSets = [];
  }
}
