import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SmartSearchComponent } from './smart-search/smart-search.component';
import { PipelinesComponent } from './pipelines/pipelines.component';
import { MapperComponent } from './mapper/mapper.component';
import { LoginComponent } from './login/login.component';
import { isAuthenticatedGuard } from './authenticated.guard';
import { codeSystemsResolver } from './code-systems.resolver';

const routes: Routes = [
  { path: '', redirectTo: '/browse', pathMatch: 'full' },
  { path: 'home', redirectTo: '/browse', pathMatch: 'full' },
  { path: 'browse', component: HomeComponent, canActivate: [isAuthenticatedGuard], resolve: { codeSystems: codeSystemsResolver } },
  { path: 'search', component: SmartSearchComponent, canActivate: [isAuthenticatedGuard] },
  { path: 'pipelines', component: PipelinesComponent, canActivate: [isAuthenticatedGuard] },
  { path: 'mapper', component: MapperComponent, canActivate: [isAuthenticatedGuard] },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
