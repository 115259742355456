<h4>Categorizers</h4>
<table>
  <tbody>
    <tr *ngFor="let cat of categorizations">
      <td class="categorizer">
        <app-categorizer-label [categorizer]="cat.categorizer.toLowerCase()"></app-categorizer-label>
      </td>
      <td class="cateogry">{{cat.category}}</td>
    </tr>
  </tbody>
</table>
