import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { RosettaService } from '../services/rosetta.service';
import { finalize } from 'rxjs/operators';
import { CodingViewModel } from './coding.viewmodel';
import { Injectable } from '@angular/core';

@Injectable()
export class BasicCodingTableDataSource implements DataSource<CodingViewModel>
{
  public codings$: Observable<CodingViewModel[]>;
  public loading$: Observable<boolean>;
  public searchCount$: Observable<number>;

  private codingsSubject = new BehaviorSubject<CodingViewModel[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private searchCountSubject = new ReplaySubject<number>(1);

  constructor(private rosetta: RosettaService) {
    this.codings$ = this.codingsSubject.asObservable();
    this.loading$ = this.loadingSubject.asObservable();
    this.searchCount$ = this.searchCountSubject.asObservable();
  }

  connect(collectionViewer: CollectionViewer): Observable<CodingViewModel[]> {
    return this.codingsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.codingsSubject.complete();
    this.loadingSubject.complete();
  }

  load(systemId: string, pageIndex: number, pageSize: number, search: string) {
    this.loadingSubject.next(true);
    this.rosetta.searchForCodes(systemId, search, pageIndex + 1, pageSize).pipe(
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(page => {
      const viewModels = page.codings.map(c => new CodingViewModel(c));
      this.codingsSubject.next(viewModels);
      this.searchCountSubject.next(page.searchTotal);
    });
  }
}
