import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { CodeSystem } from '../models/code-system.model';
import { ValueSetReference } from '../models/value-set-reference.model';
import { RosettaService } from '../services/rosetta.service';
import { BasicCodingTableDataSource } from './basic-coding-table.datasource';
import { CodingViewModel } from './coding.viewmodel';
import { ValueSetsDialogComponent } from './value-sets-dialog/value-sets-dialog.component';
import { ValueSetsDialogModel } from './value-sets-dialog/value-sets-dialog.model';

@Component({
  selector: 'app-basic-coding-table',
  templateUrl: './basic-coding-table.component.html',
  styleUrls: ['./basic-coding-table.component.css'],
  providers: [BasicCodingTableDataSource]
})
export class BasicCodingTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  public search$: Observable<string>;

  @Input()
  public codeSystem$: Observable<CodeSystem>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public pageSize = 10;

  public pinnedValueSets: ValueSetReference[] = [];

  private destroy$ = new ReplaySubject<void>(1);

  constructor(public dataSource: BasicCodingTableDataSource, private rosetta: RosettaService, private dialog: MatDialog) { }

  get relatedTermsColSpan(): number {
    return 3 + this.pinnedValueSets.length;
  }

  ngOnInit(): void {
    combineLatest([this.search$, this.codeSystem$]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([search, codeSystem]) => {
      this.paginator.pageIndex = 0;
      this.dataSource.load(codeSystem.id, 0, this.pageSize, search);
    });

    const cancelSubject = new Subject<void>();

    this.dataSource.codings$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.codeSystem$)
    ).subscribe(([codingVMs, codeSystem]) => {
      cancelSubject.next();

      for (const vm of codingVMs) {
        this.rosetta.getAdditionalInfo(codeSystem.id, vm.code, cancelSubject).subscribe(additionalInfo => {
          vm.relatedTerms = additionalInfo.relatedTerms;
          vm.valueSets = additionalInfo.valueSets;
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.search$, this.codeSystem$)
    ).subscribe(([page, search, codeSystem]) => this.dataSource.load(codeSystem.id, page.pageIndex, this.pageSize, search));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hasRelatedTerms(item: CodingViewModel): boolean {
    return item.relatedTerms && item.relatedTerms.length > 0;
  }

  hasValueSets(item: CodingViewModel): boolean {
    return item.valueSets && item.valueSets.length > 0;
  }

  showValueSets(item: CodingViewModel): void {
    const data = new ValueSetsDialogModel(item.code, item.display, item.valueSets, this.pinnedValueSets);
    const dialogRef = this.dialog.open(ValueSetsDialogComponent, { data });
    dialogRef.afterClosed().pipe(
      takeUntil(this.destroy$)
    ).subscribe(pinnedValueSets => this.pinnedValueSets = pinnedValueSets);
  }

  clearPinnedValueSets(): void {
    this.pinnedValueSets = [];
  }

  getCodingColumns(): string[] {
    return ['code', 'display'].concat(this.pinnedValueSets.map(vs => vs.key)).concat(['valueSets']);
  }
}
