import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { inject } from '@angular/core';

export const isAuthenticatedGuard = () => {
  const authService = inject(AuthService);
  if(authService.isAuthenticated) {
    return true;
  } else {
    const router = inject(Router);
    return router.parseUrl('/login');
  }
};
