import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { CodeSystem } from '../models/code-system.model';

@Component({
  selector: 'app-code-system-selector',
  templateUrl: './code-system-selector.component.html',
  styleUrls: ['./code-system-selector.component.css']
})
export class CodeSystemSelectorComponent implements OnInit, OnDestroy {

  @Input()
  public codeSystem: CodeSystem;

  @Output()
  public codeSystemChange = new EventEmitter<CodeSystem>();

  @Input()
  public codeSystems: CodeSystem[];

  public filteredCodeSystems$: Observable<CodeSystem[]>;

  public codeSystemFilterCtrl = new UntypedFormControl();

  private destroy$ = new ReplaySubject<void>(1);

  constructor() { }

  ngOnInit(): void {
    this.filteredCodeSystems$ = this.codeSystemFilterCtrl.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(''),
      map((search) => {
        if (!search) {
          return this.codeSystems;
        }

        const searchValue = search.toLowerCase();

        return this.codeSystems.filter(cs => cs.name.toLowerCase().indexOf(searchValue) >= 0 ||
          cs.id.toLowerCase().indexOf(searchValue) >= 0);
      }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onCodeSystemSelected(id: string) {
    this.codeSystem = this.codeSystems.find(cs => cs.id === id);
    this.codeSystemChange.emit(this.codeSystem);
  }
}
