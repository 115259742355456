<ng-container>
  <mat-toolbar color="primary" role="heading" [routerLink]="['home']">
    <img src="/assets/Rosetta_teal-white.svg" alt="rosetta logo" class="logo" />
    <span class="app-title">browser</span>
    <span class="toolbar-spacer"></span>
    <span *ngIf="isAuthenticated">
      <button mat-raised-button (click)="signOut()">Log out</button>
    </span>
  </mat-toolbar>

  <nav mat-tab-nav-bar [tabPanel]="tabPanel" routerActiveLink="active-link" *ngIf="isAuthenticated">
    <button mat-tab-link routerLink="browse" routerLinkActive #rlaBrowse="routerLinkActive"
      [active]="rlaBrowse.isActive">Browse</button>
    <button mat-tab-link routerLink="search" routerLinkActive #rlaSearch="routerLinkActive"
      [active]="rlaSearch.isActive">Search</button>
    <button mat-tab-link routerLink="pipelines" routerLinkActive #rlaPipelines="routerLinkActive"
      [active]="rlaPipelines.isActive">Pipelines</button>
    <button mat-tab-link routerLink="mapper" routerLinkActive #rlaMapper="routerLinkActive"
      [active]="rlaMapper.isActive">Term&nbsp;Mapping</button>
  </nav>

  <mat-tab-nav-panel #tabPanel class="content" role="main">
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</ng-container>