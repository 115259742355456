import { Coding } from '../models/coding.model';
import { ValueSetReference } from '../models/value-set-reference.model';

export class CodingViewModel {
  public readonly coding: Coding;
  public readonly code: string;
  public readonly display: string;
  public valueSets: ValueSetReference[];
  public relatedTerms: Coding[];

  constructor(coding: Coding) {
    this.coding = coding;
    this.code = coding.code;
    this.display = coding.display;
  }

  isInValueSet(valueSet: ValueSetReference): boolean {
    if (!this.valueSets) {
      return false;
    }

    return !!this.valueSets.find(vs => vs.isMatch(valueSet));
  }
}
