import { ValueSetReference } from '../models/value-set-reference.model';

export class ValueSetViewModel {

  public cleanName: string;

  constructor(public reference: ValueSetReference, public isPinned: boolean) {
    this.cleanName = reference.name.replace(/ ?\[[^\]]+\]$/, '');
  }
}
