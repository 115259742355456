import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, Subscription } from 'rxjs';
import { Pipeline } from '../models/pipeline.model';
import { TranslateResults } from '../models/translate-results.model';
import { RosettaErrorDialogComponent } from '../rosetta-error-dialog/rosetta-error-dialog.component';
import { RosettaService } from '../services/rosetta.service';
import { TraceDialogComponent } from './trace-dialog/trace-dialog.component';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-mapper',
  templateUrl: './mapper.component.html',
  styleUrls: ['./mapper.component.css']
})
export class MapperComponent implements OnInit, OnDestroy {
  public domains: string[] = [];

  public pipeline: Pipeline = null;

  public source = new TranslateSourceModel();

  public results: TranslateResults;

  public isAuthenticated = false;

  private authSubscription: Subscription;

  constructor(
    private rosetta: RosettaService,
    private dialog: MatDialog,
    private authService: AuthService) { }

  ngOnInit(): void {
    const existingSource = localStorage.getItem('mapper/source');

    if (existingSource) {
      this.source = JSON.parse(existingSource);
    }

    this.authSubscription = this.authService.$isAuthenticated.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.onSignIn();
      } else {
        this.onSignOut();
      }
    });
  }

  ngOnDestroy(): void {
    this.authSubscription?.unsubscribe();
  }

  public cleanSource(source: TranslateSourceModel): TranslateSourceModel {
    const clean = (input: string) => {
      if (!input) { return null; }

      input = input.trim();

      if (input.length === 0) { return null; }

      return input;
    };

    const cleaned = new TranslateSourceModel();
    cleaned.system = clean(source.system);
    cleaned.domain = clean(source.domain);
    cleaned.code = clean(source.code);
    cleaned.display = clean(source.display);

    if (!cleaned.code && !cleaned.display) {
      throw new Error('At least one of code or display must be set');
    }

    if (!cleaned.code) {
      cleaned.code = 'code';
    }

    return cleaned;
  }

  public canTranslate(): boolean {
    return this.source.domain?.trim().length > 0 && (this.source.code?.trim().length > 0 || this.source.display?.trim().length > 0);
  }

  translate(): void {
    this.results = null;
    this.pipeline = null;

    const cleaned = this.cleanSource(this.source);

    this.rosetta.translate(cleaned.code, cleaned.display, cleaned.domain, cleaned.system, null, EMPTY)
      .subscribe({
        next: r => {
          this.results = r;
          this.rosetta.getCompositePipeline(this.source.domain, r.sourceSystems)
            .subscribe(p => this.pipeline = p);
        },
        error: error => {
          this.dialog.open(RosettaErrorDialogComponent, { data: error });
        }
      });
  }

  showTrace(): void {
    this.dialog.open(TraceDialogComponent, { data: this.results });
  }

  clearSource(): void {
    this.source = new TranslateSourceModel();
    this.onSourceChanged();
  }

  onSourceChanged(): void {
    localStorage.setItem('mapper/source', JSON.stringify(this.source));
  }

  private onSignIn(): void {
    this.rosetta.getPipelineDomains().subscribe(domains => this.domains = domains);
    this.rosetta.getCodeSystems();
  }

  private onSignOut(): void {
    this.domains = [];
    this.rosetta.getCodeSystems();
  }
}

export class TranslateSourceModel {
  public domain: string;
  public system: string;
  public code: string;
  public display: string;
}
