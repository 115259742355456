import { Injectable } from '@angular/core';
import { SearchString } from '../models/search-string.model';

const labObservationTypeDomain = 'LabObservationType';
const diagnosisDomain = 'DiagnosisCode';
const procedureDomain = 'Procedure';
const medicationDomain = 'Medication';

const domainMap = new Map<string, string>([
  ['lab', labObservationTypeDomain],
  ['labobservationtype', labObservationTypeDomain],
  ['dx', diagnosisDomain],
  ['diagnosis', diagnosisDomain],
  ['diagnosiscode', diagnosisDomain],
  ['procedure', procedureDomain],
  ['service', procedureDomain],
  ['procedurecode', procedureDomain],
  ['servicecode', procedureDomain],
  ['rx', medicationDomain],
  ['med', medicationDomain],
  ['medication', medicationDomain],
  ['vaccine', medicationDomain]
]);

@Injectable({
  providedIn: 'root'
})
export class SearchParserService {

  public parse(search: string): SearchString {
    const searchTags = new SearchString();

    if (!search) { return searchTags; }

    const words = search.toLowerCase().split(/\s+/).filter(w => w.length > 0);
    const searchWords = [];

    for (const word of words) {
      let isSearchWord = true;
      if (!searchTags.domain && domainMap.has(word)) {
        searchTags.domain = domainMap.get(word);
        isSearchWord = false;
      }
      if (isSearchWord) {
        searchWords.push(word);
      }
    }

    searchTags.search = searchWords.join(' ');

    return searchTags;
  }
}
