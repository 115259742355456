import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { HighlightService } from '../../services/highlight.service';

@Component({
  selector: 'app-code-system-label',
  templateUrl: './code-system-label.component.html',
  styleUrls: ['./code-system-label.component.scss']
})
export class CodeSystemLabelComponent implements OnInit, OnDestroy {

  @Input()
  public codeSystem: string;

  public shouldHighlight = false;

  private highlightSubscription: Subscription;

  constructor(private highlightService: HighlightService) {

  }

  ngOnInit(): void {
    this.highlightSubscription = this.highlightService.codeSystem$
      .subscribe(codeSystem => this.shouldHighlight = this.codeSystem === codeSystem);
  }

  ngOnDestroy(): void {
    this.highlightSubscription.unsubscribe();
  }

  onMouseEnter(): void {
    this.highlightService.startHighlightingCodeSystem(this.codeSystem);
  }

  onMouseLeave(): void {
    this.highlightService.stopHighlightingCodeSystem();
  }
}
