<h3><span class="title">{{pipeline.title}}</span> Pipeline</h3>
<div class="pipeline-details-container">
  <div class="categorizers">
    <h4>Categorizers</h4>
    <div *ngIf="pipeline?.categorizers?.length === 0">None</div>
    <table class="mat-table">
      <thead>
        <tr class="mat-header-row" class="help">
          <td colspan="3">
            <span class="circled">1</span> Rosetta categorizes the source term
          </td>
        </tr>
      </thead>
      <tr class="mat-row" *ngFor="let categorizer of pipeline.categorizers">
        <td class="mat-cell">
          <app-categorizer-label [categorizer]="categorizer.name"></app-categorizer-label>
        </td>
        <td class="mat-cell cat-type">{{categorizer.type}}</td>
      </tr>
    </table>
  </div>
  <div class="mappers">
    <table class="pipeline mat-table">
      <tr class="mat-header-row">
        <th colspan="4">Primary</th>
      </tr>
      <tr class="mat-header-row" class="help">
        <td colspan="4"><span class="circled">2</span>
          Rosetta runs Primary Mappers in order, unless there is already a mapping to the target system</td>
      </tr>
      <tr class="mat-header-row">
        <th class="mat-header-cell">Mapper</th>
        <th class="mat-header-cell"></th>
        <th class="mat-header-cell">Target System</th>
      </tr>
      <tr *ngFor="let mapper of pipeline.primary" class="mat-row">
        <td class="mat-cell">
          <app-mapper-label [mapper]="mapper.mapperId" [categorizers]="mapper.categorizers"></app-mapper-label>
        </td>
        <td class="mat-cell mapper-sub-type">{{mapper.mapperSubType}}</td>
        <td class="mat-cell">
          <app-code-system-label [codeSystem]="mapper.targetCodeSystem"></app-code-system-label>
        </td>
      </tr>
      <tr class="mat-header-row">
        <th colspan="4">Fallback</th>
      </tr>
      <tr class="mat-header-row" class="help">
        <td colspan="4"><span class="circled">3</span> If there are no mappings, Rosetta runs Fallback Mappers in
          order,
          unless there is already a mapping to the target system</td>
      </tr>
      <tr *ngFor="let mapper of pipeline.fallback" class="mat-row">
        <td class="mat-cell">
          <app-mapper-label [mapper]="mapper.mapperId" [categorizers]="mapper.categorizers"></app-mapper-label>
        </td>
        <td class="mat-cell mapper-sub-type">{{mapper.mapperSubType}}</td>
        <td class="mat-cell">
          <app-code-system-label [codeSystem]="mapper.targetCodeSystem"></app-code-system-label>
        </td>
      </tr>
    </table>
  </div>
  <div class="auxiliary">
    <h4>Crosswalks</h4>
    <div *ngIf="pipeline?.crosswalks?.length === 0">None</div>
    <table class="mat-table">
      <thead>
        <tr class="mat-header-row" class="help">
          <td colspan="3">
            <span class="circled">✱</span> Rosetta applies crosswalks as soon as it has a mapping from any mapper or
            other crosswalk
          </td>
        </tr>
      </thead>
      <tr class="mat-row" *ngFor="let crosswalk of pipeline.crosswalks">
        <td class="mat-cell crosswalk-source">
          <app-code-system-label [codeSystem]="crosswalk.source"></app-code-system-label>
        </td>
        <td class="mat-cell">⇨</td>
        <td class="mat-cell">
          <app-code-system-label [codeSystem]="crosswalk.target"></app-code-system-label>
        </td>
      </tr>
    </table>
  </div>
</div>
