import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HighlightService {
  public codeSystem$: Observable<string>;
  public categorizers$: Observable<string[]>;

  private codeSystemSubject = new Subject<string>();
  private categorizersSubject = new Subject<string[]>();

  constructor() {
    this.codeSystem$ = this.codeSystemSubject.pipe(
      share()
    );

    this.categorizers$ = this.categorizersSubject.pipe(
      share()
    );
  }

  startHighlightingCodeSystem(codeSystem: string): void {
    this.codeSystemSubject.next(codeSystem);
  }

  stopHighlightingCodeSystem(): void {
    this.codeSystemSubject.next(null);
  }

  startHighlightingCategorizer(categorizers: string[]): void {
    this.categorizersSubject.next(categorizers);
  }

  stopHighlightingCategorizer(): void {
    this.categorizersSubject.next(null);
  }
}

