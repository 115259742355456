<div class="pipeline-container content">
  <div class="index-list">
    <h4>Domain</h4>
    <mat-form-field>
      <mat-label>Domain</mat-label>
      <mat-select (selectionChange)="setDomain()" [(ngModel)]="selectedDomain">
        <mat-option *ngFor="let domain of domains" [value]="domain">{{domain}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-stroked-button type="button" (click)="clearDomain()">Clear</button>

    <h4>Code System</h4>
    <mat-form-field>
      <mat-label>Code System</mat-label>
      <mat-select (selectionChange)="setCodeSystems()" [(ngModel)]="selectedCodeSystems" multiple>
        <mat-option *ngFor="let codeSystem of codeSystems" [value]="codeSystem">{{codeSystem}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-stroked-button type="button" (click)="clearCodeSystem()">Clear</button>
  </div>

  <div class="detail" *ngIf="pipeline">
    <app-pipeline-detail [pipeline]="pipeline"></app-pipeline-detail>
  </div>
</div>

