import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { httpInterceptorProviders } from './http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { CodeSystemSelectorComponent } from './code-system-selector/code-system-selector.component';
import { BasicCodingTableComponent } from './basic-coding-table/basic-coding-table.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SmartSearchComponent } from './smart-search/smart-search.component';

import { PipelinesComponent } from './pipelines/pipelines.component';
import { PipelineDetailComponent } from './pipelines/pipeline-detail/pipeline-detail.component';
import { CodeSystemLabelComponent } from './pipelines/code-system-label/code-system-label.component';
import { CategorizerLabelComponent } from './pipelines/categorizer-label/categorizer-label.component';
import { MapperLabelComponent } from './pipelines/mapper-label/mapper-label.component';
import { MapperComponent } from './mapper/mapper.component';
import { CategorizationComponent } from './mapper/categorization/categorization.component';
import { TranslateResultsComponent } from './mapper/translate-results/translate-results.component';
import { TraceDialogComponent } from './mapper/trace-dialog/trace-dialog.component';
import { RosettaErrorDialogComponent } from './rosetta-error-dialog/rosetta-error-dialog.component';
import { ValueSetsDialogComponent } from './basic-coding-table/value-sets-dialog/value-sets-dialog.component';

import { TimesPipe } from './times-pipe';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CodeSystemSelectorComponent,
    BasicCodingTableComponent,
    SearchBarComponent,
    SmartSearchComponent,
    PipelinesComponent,
    PipelineDetailComponent,
    CodeSystemLabelComponent,
    CategorizerLabelComponent,
    MapperLabelComponent,
    MapperComponent,
    CategorizationComponent,
    TranslateResultsComponent,
    TraceDialogComponent,
    RosettaErrorDialogComponent,
    ValueSetsDialogComponent,
    TimesPipe,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
