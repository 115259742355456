import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Pipeline } from '../models/pipeline.model';
import { RosettaErrorDialogComponent } from '../rosetta-error-dialog/rosetta-error-dialog.component';
import { RosettaService } from '../services/rosetta.service';

@Component({
  selector: 'app-pipelines',
  templateUrl: './pipelines.component.html',
  styleUrls: ['./pipelines.component.css']
})
export class PipelinesComponent implements OnInit, OnDestroy {
  public domains: string[] = [];
  public codeSystems: string[] = [];

  public pipeline: Pipeline = null;

  public selectedDomain: string;
  public selectedCodeSystems: string[];

  private queryParamsSubscription: Subscription;

  constructor(
    private rosetta: RosettaService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit(): void {
    this.rosetta.getCodeSystems();
    this.rosetta.getPipelineCodeSystems().subscribe(codeSystems => this.codeSystems = codeSystems);
    this.rosetta.getPipelineDomains().subscribe(domains => this.domains = domains);

    this.queryParamsSubscription = this.route.queryParamMap.subscribe(params => {
      this.selectedDomain = params.get('domain');
      this.selectedCodeSystems = params.get('codeSystems')?.split(',');

      if (this.selectedDomain && this.selectedCodeSystems) {

        this.rosetta.getCompositePipeline(this.selectedDomain, this.selectedCodeSystems)
          .subscribe({
            next: pipeline => this.pipeline = pipeline,
            error: error => this.handleRosettaError(error)
          });

      } else if (this.selectedDomain) {

        this.rosetta.getDomainPipeline(this.selectedDomain)
          .subscribe({
            next: pipeline => this.pipeline = pipeline,
            error: error => this.handleRosettaError(error)
          });

      } else if (this.selectedCodeSystems?.length > 1) {

        this.rosetta.getCompositePipeline(null, this.selectedCodeSystems)
          .subscribe({
            next: pipeline => this.pipeline = pipeline,
            error: error => this.handleRosettaError(error)
          });

      } else if (this.selectedCodeSystems?.length === 1) {
        this.rosetta.getCodeSystemPipeline(this.selectedCodeSystems[0])
          .subscribe({
            next: pipeline => this.pipeline = pipeline,
            error: error => this.handleRosettaError(error)
          });

      } else {
        this.pipeline = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription?.unsubscribe();
  }

  setDomain(): void {
    this.navigateWithQueryParams();
  }

  setCodeSystems(): void {
    this.navigateWithQueryParams();
  }

  clearDomain(): void {
    this.selectedDomain = null;
    this.navigateWithQueryParams();
  }

  clearCodeSystem(): void {
    this.selectedCodeSystems = null;
    this.navigateWithQueryParams();
  }

  private navigateWithQueryParams(): void {

    const queryParams: any = {};

    if (this.selectedCodeSystems?.length > 0) {
      queryParams.codeSystems = this.selectedCodeSystems.join(',');
    }

    if (this.selectedDomain) {
      queryParams.domain = this.selectedDomain;
    }

    this.router.navigate(['/pipelines'], { queryParams });
  }

  private handleRosettaError(error: HttpErrorResponse): void {
    this.dialog.open(RosettaErrorDialogComponent, { data: error });
  }
}
