import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Output()
  public searchChange = new EventEmitter<string>();

  @Input()
  public search$: Observable<string>;

  private destroy$ = new ReplaySubject<void>(1);

  ngOnInit(): void {
    this.search$.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(150)
    ).subscribe(s => this.setSearch(s));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setSearch(search: string) {
    this.searchChange.emit(search);
  }
}
