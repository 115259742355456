import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CodeSystem } from '../models/code-system.model';
import { Coding } from '../models/coding.model';
import { CodingPage } from '../models/coding-page.model';
import { ValueSetReference } from '../models/value-set-reference.model';
import { CodingAdditionalInfo } from '../models/coding-additional-info.model';
import { Categorization, TranslateMapping, TranslateResults } from '../models/translate-results.model';
import { Pipeline } from '../models/pipeline.model';

const processPipeline = (pipeline: Pipeline): Pipeline => {
  pipeline.categorizers.forEach(c => c.name = c.name.toLowerCase());
  pipeline.primary.concat(pipeline.fallback).forEach(m => m.categorizers = m.categorizers.map(c => c.toLowerCase()));
  return pipeline;
};

const getStringExtension = (resource: any, url: string): string => resource.extension.find((ext: any) => ext.url === url).valueString;

const getIntExtension = (resource: any, url: string): number => resource.extension.find((ext: any) => ext.url === url).valueInteger;

const getBooleanExtension = (resource: any, url: string): boolean => {
  if (!resource.extension) {
    return false;
  }

  const extension = resource.extension.find((ext: any) => ext.url === url);
  if (!extension) {
    return false;
  }
  return extension.valueBoolean;
};

export interface LookupResult {
  sourceCoding: Coding;
  validatedCoding: Coding;
  error: string;
}

const rosettaUrl = 'https://api.rosetta.careevolution.com/terminology/v1';

@Injectable({
  providedIn: 'root'
})
export class RosettaService {
  public codeSystems: CodeSystem[] = [];

  private codeSystemsByUrl: Map<string, CodeSystem>;
  private cancelGetCodeSystems = new Subject();

  constructor(private http: HttpClient) {
  }

  getCodeSystems(): Observable<CodeSystem[]> {
    if (this.codeSystems.length > 0) {
      return of(this.codeSystems);
    }

    this.cancelGetCodeSystems.next(null);

    const request = this.http.get(this.buildUrl('/FHIR/STU3/CodeSystem?_summary=true')).pipe(
      takeUntil(this.cancelGetCodeSystems),
      map((bundle: any) => {
        const codeSystems = bundle.entry.map((entry: any) => new CodeSystem(
          entry.resource.id,
          entry.resource.name,
          entry.resource.url,
          entry.resource.count,
          getBooleanExtension(entry.resource, 'sort-as-string'),
          getBooleanExtension(entry.resource, 'sort-with-domain')));

        return codeSystems;
      }));

    request.subscribe((codeSystems) => {
      this.codeSystems = codeSystems;
      this.codeSystemsByUrl = new Map<string, CodeSystem>();
      this.codeSystems.forEach(cs => this.codeSystemsByUrl.set(cs.url, cs));
    });

    return request;
  }

  getPipelineDomains(): Observable<string[]> {
    return this.http.get(this.buildUrl('/pipelines/domains')).pipe(
      map((value: string[]) => {
        value.sort();
        return value;
      })
    );
  }

  getDomainPipeline(domain: string): Observable<Pipeline> {
    return this.http.get(this.buildUrl('/pipelines/domain/' + domain)).pipe(
      map(processPipeline)
    );
  }

  getPipelineCodeSystems(): Observable<string[]> {
    return this.http.get(this.buildUrl('/pipelines/codeSystems')).pipe(
      map((value: string[]) => {
        value.sort();
        return value;
      })
    );
  }

  getCodeSystemPipeline(codeSystem: string): Observable<Pipeline> {
    return this.http.get(this.buildUrl('/pipelines/codeSystem/' + codeSystem)).pipe(
      map(processPipeline)
    );
  }

  getCompositePipeline(domain: string, codeSystemIds: string[]): Observable<Pipeline> {
    return this.http.post(this.buildUrl('/pipelines/composite'), { domain, codeSystemIds }).pipe(
      map(processPipeline)
    );
  }

  searchForCodes(systemId: string, search: string, pageNum: number, pageSize: number): Observable<CodingPage> {
    const url = this.buildUrl(`/FHIR/STU3/CodeSystem/${systemId}`);
    let params = new HttpParams()
      .append('page.num', (pageNum - 1).toString())
      .append('_count', pageSize.toString());

    if (search && search !== '') {
      params = params.append('concept:contains', search);
    }
    return this.http.get(url, { params }).pipe(
      map((fhirCodeSystem: any) => {
        const searchTotal = getIntExtension(fhirCodeSystem, 'search-total');

        if (fhirCodeSystem.concept) {
          const codeSystem = this.codeSystemsByUrl.get(fhirCodeSystem.url);

          if (!codeSystem) {
            console.error(`could not find code system with url ${fhirCodeSystem.url}`);
            return new CodingPage([], 0, pageSize, 0);
          }

          const codings = fhirCodeSystem.concept.map((c: any) => new Coding(
            codeSystem.id,
            c.code,
            c.display));
          return new CodingPage(codings, pageNum, pageSize, searchTotal);
        } else {
          return new CodingPage([], pageNum, pageSize, searchTotal);
        }
      })
    );
  }

  getAdditionalInfo(systemId: string, code: string, cancel: Observable<any>): Observable<CodingAdditionalInfo> {
    const url = this.buildUrl(`/FHIR/STU3`);

    const classifyQuery = new HttpParams()
      .append('code', code)
      .append('system', systemId);

    const lookupQuery = new HttpParams()
      .append('code', code);

    const bundle = {
      resourceType: 'Bundle',
      type: 'batch',
      entry: [{
        request: {
          method: 'GET',
          url: `ValueSet/$classify?${classifyQuery}`
        }
      }, {
        request: {
          method: 'GET',
          url: `CodeSystem/${systemId}/$lookup?${lookupQuery}`
        }
      }]
    };

    return this.http.post(url, bundle).pipe(
      takeUntil(cancel),
      map((respBundle: any) => {
        const valueSetRefs = this.parseClassifyResponse(respBundle.entry[0].resource);
        const relatedTerms = this.parseLookupResults(respBundle.entry[1].resource);

        return new CodingAdditionalInfo(valueSetRefs, relatedTerms);
      }));
  }

  public translate(
    code: string,
    display: string,
    domain: string,
    codeSystemId: string,
    codeSystemName: string,
    cancel: Observable<any>): Observable<TranslateResults> {

    if (!codeSystemId) {
      codeSystemId = 'some-code-system';
    }

    const body: any = {
      resourceType: 'Parameters',
      parameter: [{
        name: 'coding',
        valueCoding: {
          system: codeSystemId,
          code
        }
      }]
    };

    if (display) {
      body.parameter[0].valueCoding.display = display;
    }

    if (codeSystemName) {
      body.parameter[0].valueCoding.extension = [{
        url: 'https://rosetta.careevolution.com/api/FHIR/STU3/translate-extension/namespace-name',
        valueString: codeSystemName
      }];
    }

    if (domain) {
      body.parameter.push({
        name: 'domain',
        valueString: domain
      });
    }

    return this.http.post(this.buildUrl('/FHIR/STU3/ConceptMap/$translate'), body).pipe(
      takeUntil(cancel),
      map((response: any) => {
        const mappings: TranslateMapping[] = [];
        const categorizations: Categorization[] = [];
        const sourceSystems: string[] = [];
        let trace: string;

        for (const parameter of response.parameter) {
          if (parameter.name === 'result' || parameter.name === 'Rosetta.ApiVersion' || parameter.name === 'Rosetta.TermsVersion') {
            continue;
          }

          if (parameter.name === 'message' || parameter.name === 'trace') {
            trace = parameter.valueString;
            continue;
          }

          if (parameter.name === 'categorization') {
            const categorization = new Categorization(parameter.valueCoding.system, parameter.valueCoding.code);
            categorizations.push(categorization);
            continue;
          }

          if (parameter.name === 'source-system') {
            const sourceSystem = this.codeSystemsByUrl.get(parameter.valueUri);

            if (!sourceSystem) {
              throw new Error('Could not find system for ' + parameter.valueUri);
            } else {
              sourceSystems.push(sourceSystem.id);
            }
          }

          if (parameter.name !== 'match') {
            continue;
          }

          let equivalence: string;
          let target: Coding;
          let technique: string;
          let detail: string;
          let confidence: number;
          let mapper: string;
          let crosswalkSourceSystemId: string;

          for (const part of parameter.part) {
            switch (part.name) {
              case 'equivalence':
                equivalence = part.valueCode;
                break;
              case 'concept':
                const targetCodeSystem = this.codeSystemsByUrl.get(part.valueCoding.system);

                if (!targetCodeSystem) {
                  throw new Error('could not find target code system for ' + part.valueCoding.system);
                } else {
                  const targetCodeSystemId = targetCodeSystem.id;

                  target = new Coding(targetCodeSystemId, part.valueCoding.code, part.valueCoding.display);
                }
                break;
              case 'Rosetta.Technique':
                technique = part.valueCode;
                break;
              case 'Rosetta.Detail':
                detail = part.valueCode;
                break;
              case 'confidence':
                confidence = part.valueDecimal;
                break;
              case 'source':
                const url = new URL(part.valueString);
                mapper = url.pathname.split('/').pop();
                break;
              case 'Rosetta.CrosswalkSource':
                const crosswalkSourceSystem = this.codeSystemsByUrl.get(part.valueCoding.system);
                if (!crosswalkSourceSystem) {
                  throw new Error('could not find crosswalk source system for ' + part.valueCoding.system);
                } else {
                  crosswalkSourceSystemId = crosswalkSourceSystem.id;
                }
                break;
            }
          }

          if (!target) {
            throw new Error('Invalid response from Rosetta: could not determine target concept');
          }

          if (equivalence === 'inexact') {
            if (confidence) {
              confidence *= 100;
            }
            if (!technique) {
              technique = 'Suggestion';
            }
          }

          mappings.push(new TranslateMapping(target, equivalence, technique, detail, confidence, mapper, crosswalkSourceSystemId));
        }

        return new TranslateResults(categorizations, mappings, sourceSystems, trace);
      }));
  }

  private parseClassifyResponse(fhirParams: any): ValueSetReference[] {
    if (!fhirParams || !fhirParams.parameter) {
      return [];
    }

    const valueSetRefs = fhirParams.parameter[0].part.filter((part: any) => part.name === 'classification').map((classification: any) => {
      let name: string = null;
      let scope: string = null;

      for (const classPart of classification.part) {
        if (classPart.name === 'name') {
          name = classPart.valueString;
        } else if (classPart.name === 'scope') {
          scope = classPart.valueString;
        }
      }

      return new ValueSetReference(scope, name);
    });

    return valueSetRefs;
  }

  private parseLookupResults(fhirParams: any): Coding[] {
    const related: Coding[] = [];
    for (const parameter of fhirParams.parameter) {
      if (parameter.name !== 'property') { continue; }

      let crosswalk: Coding;
      let isCrosswalk = false;

      for (const part of parameter.part) {
        if (part.name === 'code' && part.valueCode === 'crosswalk') {
          isCrosswalk = true;
        } else if (part.name === 'value' && part.valueCoding) {
          const relatedCodeSystemId = this.codeSystemsByUrl.get(part.valueCoding.system).id;

          crosswalk = new Coding(relatedCodeSystemId, part.valueCoding.code, part.valueCoding.display);
        }
      }

      if (isCrosswalk && crosswalk) {
        related.push(crosswalk);
      }
    }

    return related;
  }

  private parseTranslateResults(fhirParams: any): Coding[] {
    const related: Coding[] = [];
    for (const parameter of fhirParams.parameter) {
      if (parameter.name !== 'match') { continue; }

      let relatedEquivalence: string;

      for (const part of parameter.part) {
        if (part.name === 'equivalence') {
          relatedEquivalence = part.valueCode;
        } else if (part.name === 'concept') {
          const relatedCodeSystemId = this.codeSystemsByUrl.get(part.valueCoding.system).id;

          related.push(new Coding(relatedCodeSystemId, part.valueCoding.code, part.valueCoding.display));
        }
      }
    }

    return related;
  }

  private buildUrl(path: string): string {
    return rosettaUrl + path;
  }
}
