<h2 mat-dialog-title>Value Sets for {{code}} {{display}}</h2>

<mat-dialog-content class="mat-typography">
  <div *ngFor="let scope of scopes">
    <p>{{scope.scope}}</p>
    <ul>
      <li *ngFor="let valueSet of scope.valueSets">
        <mat-checkbox [checked]="valueSet.isPinned" (change)="onPinnedChanged(valueSet, $event.checked)">
          {{valueSet.cleanName}}
        </mat-checkbox>
      </li>
    </ul>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onCloseClick()">Close</button>
</mat-dialog-actions>
