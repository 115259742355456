export class ValueSetReference {
  public key: string;

  constructor(public scope: string, public name: string) {
    this.key = this.scope + '|' + this.name;
  }

  public isMatch(other: ValueSetReference): boolean {
    return other.scope === this.scope && other.name === this.name;
  }
}
