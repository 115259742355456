import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { RosettaService } from './services/rosetta.service';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'rosetta-browser';
  public apiKey: string = null;
  public $ready: Observable<boolean>;
  private readySubject: BehaviorSubject<boolean>;

  constructor(
    private authService: AuthService,
    private rosetta: RosettaService,
    private router: Router) {
    this.readySubject = new BehaviorSubject(false);
    this.$ready = this.readySubject.asObservable();
  }

  get isAuthenticated(): boolean { return this.authService.isAuthenticated; }

  ngOnInit(): void {
    this.authService.$isAuthenticated.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        firstValueFrom(this.rosetta.getCodeSystems())
          .then(() => this.readySubject.next(true));
      }
    });

    if (!this.authService.isAuthenticated) {
      this.router.navigate(['login']);
    }
  }

  signIn(): void {
    this.router.navigate(['login']);
  }

  signOut(): void {
    this.rosetta.codeSystems = [];
    this.apiKey = null;
    this.authService.onSignOut();
    this.router.navigate(['login']);
  }
}
