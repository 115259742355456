import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HighlightService } from 'src/app/services/highlight.service';

@Component({
  selector: 'app-categorizer-label',
  templateUrl: './categorizer-label.component.html',
  styleUrls: ['./categorizer-label.component.scss']
})
export class CategorizerLabelComponent implements OnInit, OnDestroy {

  @Input()
  public categorizer: string;

  public shouldHighlight = false;

  private highlightSubscription: Subscription;

  constructor(private highlightService: HighlightService) {

  }

  ngOnInit(): void {
    this.highlightSubscription = this.highlightService.categorizers$
      .subscribe(categorizers => this.shouldHighlight = categorizers?.indexOf(this.categorizer) >= 0);
  }

  ngOnDestroy(): void {
    this.highlightSubscription.unsubscribe();
  }

  onMouseEnter(): void {
    this.highlightService.startHighlightingCategorizer([this.categorizer]);
  }

  onMouseLeave(): void {
    this.highlightService.stopHighlightingCategorizer();
  }
}
