export class CodeSystem {
  public static readonly hcpcs = 'HCPCS';
  public static readonly snomed = 'SNOMED';
  public static readonly icd9CmDiagnosis = 'ICD-9-CM-Diagnosis';
  public static readonly icd9CmProcedure = 'ICD-9-CM-Procedure';
  public static readonly icd10Cm = 'ICD-10-CM';
  public static readonly icd10Pcs = 'ICD-10-PCS';

  constructor(
    public id: string,
    public name: string,
    public url: string,
    public count: number,
    public sortAsString: boolean,
    public sortWithDomain: boolean) {
  }

}
